<template>
    <div ref="popup" class="popup-wrapper">
        <div class="popup-close" @click="closePopup">
            <img src="@/assets/icons/popup-close-icon.svg" alt="" />
        </div>
        <div class="popup-body">
            <div v-if="title.length" class="popup-text">
                <div class="popup-title text-h3">{{ title }}</div>
            </div>
            <transition name="media-load">
                <slot></slot>
            </transition>
        </div>
        <div v-if="hideLoader" class="media-loader"></div>
        <div class="popup-bg" @click="closePopup"></div>
    </div>
</template>

<script>
export default {
    name: 'PopupComponent',
    props: {
        hideLoader: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: '',
        },
    },
    emits: ['closePopup'],
    mounted() {
        document.addEventListener('keyup', this.onKeyUp)
    },
    beforeUnmount() {
        document.removeEventListener('keyup', this.onKeyUp)
    },
    methods: {
        onKeyUp(e) {
            if (e.key === 'Escape') {
                this.closePopup()
            }
        },

        closePopup() {
            this.$emit('closePopup')
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';

.popup {
    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        width: 100%;
        height: 100%;
    }

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(2, 0, 36);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
        z-index: -1;
    }

    &-close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        z-index: 5;
        cursor: pointer;

        img {
            width: 3rem;
            height: 3rem;
        }
    }

    &-body {
        height: 100%;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-text {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        padding: 1rem 3rem;
        justify-content: center;
        background: rgba(202, 202, 202, 0.8);
        border-radius: 0.8rem;
        margin: 1rem;
    }

    &-title {
        max-height: 6.8rem;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        z-index: 1;
    }
}

@media screen and (max-width: 767px) {
    .popup {
        &-text {
            margin: 0;
        }
    }
}
</style>
